// import * as amplitude from '@amplitude/analytics-browser';
import { trpc } from '@/utils/trpc';

export function useError404() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const error404Track = () => {
    track({
      event_type: 'Error404',
      event_data: { screen: 'Error 404' },
    });
    // amplitude.track('Error404');
  };
  return {
    error404Track,
  };
}
