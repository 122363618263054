import AppTemplate from '@/components/templates/AppTemplate';
import Button from '@/components/atoms/@next/Button';
import Head from 'next/head';
import Image from 'next/image';
import { NextPageWithLayout } from './_app';
import React from 'react';
import Typography from '@/components/atoms/@next/Typography';
import VerticalLayout from '@/components/layouts/VerticalLayout';
import notFoundImg from '@/images/404-error.png';
import { useError404 } from '@/hooks/amplitude/useError404';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useContentful404 from '@/hooks/contenful/useContentful404';
import LoadingSpinner from '@/components/atoms/LoadingSpinner';

const PageNotFound: NextPageWithLayout = () => {
  const { error404Track } = useError404();
  const router = useRouter();

  useEffect(() => {
    error404Track();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToHome = () => {
    router.push('/');
  };

  const page = useContentful404();

  if (!page) {
    return (
      <VerticalLayout className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </VerticalLayout>
    );
  }

  const {
    items: { title, btn },
  } = page;

  return (
    <>
      <Head>
        <title>Page Not Found | Suma</title>
      </Head>
      <VerticalLayout gapClassName="gap-12 bg-sumawealth-neutral-0 container px-5 py-10 lg:w-2/5 mx-auto rounded-3xl h-full">
        <Typography variant="heading" size="32px" className="text-center mb-10 md:mb-0">
          {title.text}
        </Typography>
        <VerticalLayout className="w-4/5 md:w-3/5 mx-auto" gapClassName="gap-3 md:gap-6">
          <Image
            width={789}
            height={600}
            src={notFoundImg}
            alt="A power cable has become unplugged. Text says: 'Error Code: 404'."
            className="object-contain max-w-xs"
          />
          <Button onClick={goToHome}>{btn.text}</Button>
        </VerticalLayout>
      </VerticalLayout>
    </>
  );
};

PageNotFound.getLayout = (page: React.ReactElement) => <AppTemplate>{page}</AppTemplate>;

export default PageNotFound;
